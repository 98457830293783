import {
  Box,
  Heading,
  Image,
  // useColorMode,
  SimpleGrid,
  Text,
} from "@chakra-ui/react"
import { graphql } from "gatsby"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import Layout from "../components/layout"
import SEO from "../components/seo"

function embeddedAsset(node) {
  return (
    <img
      style={{
        maxWidth: "100%",
      }}
      src={node?.data?.target?.fields?.file["en-US"]?.url}
      alt=""
    />
  )
}

function embeddedParagraph(node) {
  return <p style={{ marginBottom: "1.5rem" }}>{node.content[0].value}</p>
}

const Index = ({ data, location }) => {
  // const { colorMode } = useColorMode()
  // const isLight = colorMode === "light"
  const prosjekter = data.allContentfulForsideprosjekt?.edges
  const tekst = data.allContentfulForsidetekst?.edges
  const docContent =
    tekst && tekst[0]?.node?.innhold?.raw
      ? JSON.parse(tekst[0]?.node?.innhold?.raw)
      : null
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => embeddedAsset(node),
      [BLOCKS.PARAGRAPH]: node => embeddedParagraph(node),
    },
  }

  return (
    <>
      <Layout location={location}>
        <SEO title="" />
        <Box className="sc-index-page-wrapper">
          <Box
            paddingTop={["100px", "170px", "250px", "200px"]}
            paddingBottom={["160px", "170px", "250px", "200px"]}
            className="sc-index-page-title"
          >
            <Box maxWidth="1200px" marginLeft="auto" marginRight="auto">
              <Heading
                paddingLeft="10"
                paddingRight="10"
                fontSize={["30px", "40px", "40px", "50px"]}
              >
                Velkommen til Oppdal Næringsforening
              </Heading>
            </Box>
          </Box>
        </Box>

        <Box className="sc-index-page-info" paddingBottom="20" paddingTop="20">
          <Text
            fontSize={["xl", "xl", "2xl", "2xl"]}
            maxWidth="800px"
            marginLeft="auto"
            marginRight="auto"
            color="white"
            fontWeight="bold"
            padding={["30px", "30px", "0px", "0px"]}
          >
            <div>{documentToReactComponents(docContent, options)}</div>
          </Text>
        </Box>

        <Box p={5} maxWidth="1200px" marginLeft="auto" marginRight="auto">
          <Heading size="lg" paddingTop="20" paddingBottom="10">
            <FormattedMessage id="projectsTitle" />
          </Heading>
          <SimpleGrid columns={[1, 2, 3, 4]} spacing={5}>
            {prosjekter
              .sort((a, b) => a.node?.priority - b.node?.priority)
              .map(prosjekt => {
                const prosj = prosjekt.node
                return (
                  <div key={prosj.title}>
                    <Box
                      maxW="sm"
                      borderWidth="1px"
                      borderRadius="lg"
                      overflow="hidden"
                      boxShadow="md"
                    >
                      <Link to={prosj.link}>
                        {prosj.image ? (
                          <Image
                            src={prosj.image?.fluid?.src}
                            alt={prosj.title}
                            height={"180px"}
                            width="100%"
                          />
                        ) : (
                          <img
                            src={
                              data.fallbackperson?.childImageSharp?.fluid.src
                            }
                            alt={prosj.title}
                          />
                        )}
                        <Box p="6">
                          <Box
                            mt="1"
                            fontWeight="semibold"
                            as="h4"
                            lineHeight="tight"
                            isTruncated
                          >
                            {prosj.title}
                          </Box>
                        </Box>
                      </Link>
                    </Box>
                  </div>
                )
              })}
          </SimpleGrid>
        </Box>
      </Layout>
    </>
  )
}

Index.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default Index

export const pageQuery = graphql`
  query {
    allContentfulForsideprosjekt {
      edges {
        node {
          title
          link
          image {
            fluid {
              src
            }
          }
          priority
        }
      }
    }
    allContentfulForsidetekst {
      edges {
        node {
          innhold {
            raw
          }
        }
      }
    }
  }
`
